import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
const ThankYou = ({ location }) => (
	<Layout head={{ title: 'Thank You' }} location={location}>
		<div className="container">
			<div className="thank-you text-center">
				<h1>Thanks for filling out our form!</h1>
				<br />
				<p>
					We have received your message and would like to thank you for writing
					to us. If your inquiry is urgent, please use the telephone number
					listed below to talk to one of our staff members. Otherwise, we will
					reply by email as soon as possible.
				</p>
				<Link className="btn btn-blue mb-5" to="/">
					Go back to the homepage
				</Link>
			</div>
		</div>
	</Layout>
)
export default ThankYou
